import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class UserHome extends Component {
    render () {
        return (
            <Row className="text-center">
                <Col>
                    <h1>Hello!</h1>
                    <p className="text-muted">
                        Thank you for visiting my website! I plan on improving this website in the future with new
                        and cool things I learn about web development.
                    </p>
                </Col>
                
            </Row>
        );
    }
}

export default UserHome;