import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Jumbotron from 'react-bootstrap/Jumbotron';

import ProfilePicture from './images/me.jpg';
import GHLogo from './images/github-logo.svg';
import IGLogo from './images/instagram-logo.svg';
import LILogo from './images/linkedin-logo.svg';
import TLogo from './images/twitter-logo.svg';

class UserJumbotron extends Component {
    render () {
        return (
            <Row>
                <Jumbotron>
                    <Row className="justify-content-center">
                        <Col className="text-center" xs={{span:12, order: 3}} sm={{span:12, order: 'first'}}>
                            <Image className="profile fade-up" src={ProfilePicture} roundedCircle></Image>
                            <h2 className="text-muted fade-up">Dan Ortiz</h2>
                        </Col>
                        <Col className="text-center p-3" xs={{span: 6, order: 1}} sm={3}>
                            <a href="https://github.com/dbortiz" target="_blank" rel="noreferrer">
                                <Image className="logo fade-up" src={GHLogo}></Image>
                            </a>
                        </Col>
                        <Col className="text-center p-3" xs={{span: 6, order: 2}} sm={3}>
                            <a href="https://www.linkedin.com/in/danbortiz/" target="_blank" rel="noreferrer">
                                <Image className="logo fade-up" src={LILogo}></Image>
                            </a>
                        </Col>
                        <Col className="text-center p-3" xs={{span: 6, order: 4}} sm={3}>
                            <a href="https://twitter.com/danortizaf" target="_blank" rel="noreferrer">
                                <Image className="logo fade-up" src={TLogo}></Image>
                            </a>
                        </Col>
                        <Col className="text-center p-3" xs={{span: 6, order: 5}} sm={3}>
                            <a href="https://www.instagram.com/danortiz.af/" target="_blank" rel="noreferrer">
                                <Image className="logo fade-up" src={IGLogo}></Image>
                            </a>
                        </Col>
                    </Row>
                </Jumbotron>
            </Row>
        );
    }
}

export default UserJumbotron;