import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import UserHome from './Home';
import UserNavbar from './Navbar';
import UserJumbotron from './Jumbotron';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
import UserAbout from './About';
import UserProjects from './Projects';
import UserContact from './Contact';


const pageNotFound = () => {
  return(
    <div>
      <h1 className="text-center display-4">Oops...</h1>
      <p>Sorry, this page does not exist.</p>
      <Link to="/">Home page</Link>
    </div>
  );
};

class App extends Component {
  render() {
    return(
      <Container fluid="sm">
        <BrowserRouter>
          <UserNavbar />
          <UserJumbotron />
          <Switch>
            <Route exact path="/">
              <UserHome />
            </Route>
            <Route path="/about">
              <UserAbout />
            </Route>
            <Route path="/projects">
              <UserProjects />
            </Route>
            <Route path="/contact">
              <UserContact />
            </Route>
            <Route component={pageNotFound}/>
          </Switch>
          
        </BrowserRouter>
      </Container>
    );

  }

}


export default App;