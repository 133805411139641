import React, {Component} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import CSUF from './images/csuf.png';
import Fitness from './images/fitness.png';
import LoL from './images/lol.png';
import Gryffindor from './images/gryffindor.png';

class UserAbout extends Component {
    render() {
        return (
            <div>
                <h1 className="text-center display-4">About Me!</h1>
                <Row className="text-center justify-content-center">
                    <Col xs={{span: 12, order:'last'}} md={{span: 8, order: 'first'}}>
                        <h2>CSUF Alumni</h2>
                        <Alumni />
                    </Col>
                    <Col xs={{span: 12, order:'first'}} md={4}>
                        <Image src={CSUF} className="aboutMe my-auto"></Image>
                    </Col>
                </Row>
                
                <Row className="text-center">
                    <Col xs={{span: 12, order: 'last'}} md={{span: 8, order: 'first'}}>
                        <h2>Bookworm</h2>
                        <Bookworm />
                    </Col>
                    <Col xs={{span: 12, order: 'first'}} md={4}>
                        <Image src={Gryffindor} className="aboutMe my-auto"></Image>
                    </Col>
                </Row>
                
                <Row className="text-center">
                    <Col xs={{span: 12, order: 'last'}} md={{span: 8, order: 'first'}}>
                        <h2>Active</h2>
                        <Active />
                    </Col>
                    <Col xs={{span:12, order: 'first'}} md={4}>
                        <Image src={Fitness} className="aboutMe my-auto"></Image>
                    </Col>
                </Row>

                <Row className="text-center">
                    <Col xs={{span: 12, order: 'last'}} md={{span: 8, order: 'first'}}>
                        <h2>Gamer</h2>
                        <Gamer />
                    </Col>
                    <Col xs={{span:12, order: 'first'}} md={4}>
                        <Image src={LoL} className="aboutMe my-auto"></Image>
                    </Col>
                </Row>
                
            </div>
        );
    }
}

const Alumni = () => {
    return (
        <p className="text-muted text-left">I attended California State University, Fullerton from 2015-2020. This was a great
                achievement in my life because I am a 1st generation college graduate in my family. I learned about hardwork,
                determination, and perserverance during college which I now apply to everyday life. I met amazing people along
                the way and I am grateful for the opportunities I had both professionally and personally.</p>
    );
}

const Bookworm = () => {
    return (
        <p className="text-muted text-left">I love books! Since the beginning of COVID, I have picked up reading and it has been such a stress
        reliever because it takes me away from the stressors of reality and into a new perspective. I have made a goal for myself to read
        atleast one book a month and hope to exceed my goal as I find more good reads. My favorite genre is Fiction and so far my favorite
        books are the Harry Potter series.</p>
    );
}

const Active = () => {
    return (
        <p className="text-muted text-left">Working out has been such a huge part of my life because I was never one to be active. I was always a
        big person and it has brought some pretty traumatic experiences in my early-adult life, but now I am more energetic and happy
        because I have made great strides to being healthier. I still have a long journey until I meet my goal; but coming this far, I am
        not too worried about reaching it.</p>
    );
}

const Gamer = () => {
    return (
        <p className="text-muted text-left">I am an avid gamer and enjoy all types of games. My favorite types are puzzles, strategic, and FPS.
        I find satisfaction in completing tasks and winning so playing video games is really fun. I used to have dreams of playing 
        professionally, but I have moved past that due to competitive environments being toxic. I now enjoy casually playing with my
        friends and playing puzzle games on my phone. My favorite game, unfortunately, is League of Legends.</p>
    );
}

export default UserAbout;