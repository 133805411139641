import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Axios from 'axios';

class UserProjects extends Component {
    state = {
        projects: []
    }
    componentDidMount() {
        const url= "https://api.github.com/users/dbortiz/repos";
        Axios.get(url)
            .then((response) => {
                const projects = response.data;
                this.setState({projects});
            })
            .catch((error) => {
                console.log(error);
            });
    }
    render() {
        return (
            <div>
                <h1 className="text-center display-4">Projects!</h1>
                <p className="text-muted">These are some of my public projects I have on GitHub!</p>
                <Row className="justify-content-center">
                    {this.state.projects.map(project =>
                        <Col xs={10} md={4}  className="p-2"> 
                            <a href={project.html_url} target="_blank" className="text-reset text-decoration-none" rel="noreferrer">
                                <Card border="dark" className="h-100">
                                    <Card.Header className="h4">{project.name}</Card.Header>
                                    <Card.Body>
                                        <Card.Title>Description</Card.Title>
                                        <Card.Text>{project.description || 'No description.'}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </a>
                        </Col>
                    )}
                </Row>
            </div>
        );
    }
}

export default UserProjects;