import React, {Component} from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';

class UserNavbar extends Component {
    render() {
        return (
            <Row>
                <Navbar fixed="top" bg="dark" variant="dark" expand="sm">
                    <Navbar.Brand>
                        <Link to='/' className="text-reset text-decoration-none">Dan Ortiz's Website</Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"></Navbar.Toggle>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link>
                                <Link to='/about' className="text-reset text-decoration-none">About</Link>
                            </Nav.Link>
                            <Nav.Link>
                                <Link to='/projects' className="text-reset text-decoration-none">Projects</Link>
                            </Nav.Link>
                            <Nav.Link>
                                <Link to='/contact' className="text-reset text-decoration-none">Contact Me</Link>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Row>
        );
    }
}

export default UserNavbar;