import React, { Component } from 'react';

class UserContact extends Component {
    render() {
        return(
            <div>
                <h1 className="text-center display-4">Contact Me!</h1>
                <h4>Location:</h4>
                <p className="text-muted"> Orange County, CA</p>
                <h4>Email:</h4>
                <p className="text-muted">danbryanortiz@gmail.com</p>
            </div>
        );
    }
}

export default UserContact;